import { Link } from '@vibTheme/components/_base/Link';
import { Typography } from '@vibTheme/components/_base/Typography';

export default function Custom404() {
  return (
    <div className="text-center mt-3xl">
      <Typography component="h1" className="text-black font-bold">
        404
      </Typography>
      <Typography className="py-main">
        Não encontramos a página que você tentou acessar...
      </Typography>
      <Link href="/">Voltar para a página inicial</Link>
    </div>
  );
}
